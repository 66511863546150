.search-page .header {
    background-color: #e74c3c;
}

.search-page  .toolbar {
    background: url('/public/assets/images/exchange2.webp') no-repeat center center;
    background-size: cover;
    min-height: 430px;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 89%, 0% 100%);
}

.search-page .toolbar .image-container {}

.search-page  .toolbar .text-content {
    background: rgb(52, 152, 219, 0.5);
}

.search-page  .toolbar .image-container .image-content {
    background-position-x: -229px !important;
    background-position-y: -664px !important;
    background: url('/public/assets/images/exchange2.webp') no-repeat center center;
    background-size: cover;
}

.search-page  .toolbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(52, 152, 219, 0.5);
}

.search-page  .toolbar-content {
    height: 100%;
    position: relative;
}

.search-for-all-options {
    height: 430px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.search-for-all-options p {
    font-size: 2rem !important;
    text-align: center !important;
}

@media (max-width: 500px) {
    .search-page .toolbar {
        clip-path: none !important;
    }

    .search-page  .toolbar .image-container .image-content {
        background-position-x: -370px !important;
    }
}